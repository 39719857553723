import { declareObserver, AsyncObserver, ObjectUtil, ServiceActionName } from '@avicado/platform';
import { put } from 'redux-saga/effects';
import RefreshWidget from './RefreshWidget';


export default declareObserver(
  'application:authentication',
  'RefreshWatcher',
  class RefreshWatcher extends AsyncObserver {
    *invoke(args = {}) {
      // if (ObjectUtil.matchesObject({data: {application: /^(A|a)uthentication$/, purpose: 'widgetRefresh'}}, this.action)) {
      if (ObjectUtil.matchesObject({ data: { service: /^(S|s)ystem$/, purpose: 'heartbeat' } }, this.action)) {
        yield put(RefreshWidget.perform({ time: Date.now() }));
      }
    }
  },
  { pattern: ServiceActionName('timer')('IntervalFired') }
);


