import { Log } from '@avicado/log';
import { applicationKey, AsyncObserver, declareObserver, isRequired } from '@avicado/platform';
import { camelToStandard, initialCap } from '@avicado/type';
import { isNotNullish, isNullish } from '@avicado/util';
import { push } from 'connected-react-router';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import Platform from '../reducers/platform';
import { EnqueueSnackbar } from './enqueue-snackbar';


const EVERYTHING = (action) => true;

const separateActionParts = actionTypeName => {
  const [...parts] = actionTypeName.split('/');
  return parts;
}

const structureParts = (actionPartsArray) => {
  const [ serviceOrApp, serviceOrAppName, actionName, optModifier] = actionPartsArray;
  if (serviceOrApp.toLowerCase() === 'application') {
    return {
      isApplication: true,
      isService: false,
      applicationName: serviceOrAppName,
      actionName,
      modifier: optModifier,
    }
  } else {
    return {
      isApplication: false,
      isService: true,
      serviceName: serviceOrAppName,
      actionName,
      modifier: optModifier,
    }
  }

}

const getActionName = actionType => {
  const { actionName } = structureParts(separateActionParts(actionType));
  return actionName;
}

const getPrettyActionName = actionType => {
  const camelCaseName = getActionName(actionType);
  const prettyName = camelToStandard(camelCaseName).trim();
  return prettyName.split(' ').filter(Boolean).map(part => initialCap(part)).join(' ');
}
const log = Log.create('SnackbarError');
// eslint-disable-next-line unicorn/prevent-abbreviations
export const SnackbarMessageWatcher = declareObserver(
  applicationKey('system'),
  'SnackbarMessageWatcher',
  class SnackbarMessageWatcher extends AsyncObserver {
    *invoke(...args) {
      const { type, notificationMessage } = this.action;
      const isError = type.endsWith('/Error');
      // if (isError) console.warn('Looking at', type);

      if (isNotNullish(notificationMessage)) {
        const {
          message = isRequired('notificationMessage.message'),
          variant = isRequired('notificationMessage.variant')
        } = notificationMessage;

        yield put({ type: EnqueueSnackbar.type, message, variant });
      } else if (isError) {
        const { message, type, hideError = false, code, messageCode, remediationCode } = this.action;
        if (!hideError) {
          const prettyActionName = getPrettyActionName(type);

          yield put({
            type: EnqueueSnackbar.type,
            message: code !== 'INTERNAL_SERVER_ERROR' ? message : `${prettyActionName} Error`,
            code,
            messageCode,
            remediationCode,
            variant: 'error',
          });
        }
        log.error(message);
      }
    }
  },
  { effect: takeEvery, pattern: EVERYTHING }
);


// variant is one of 'error' 'warning' 'success' or 'info'
