import { createSelector } from 'reselect';
import selectAuthenticationService from './authenticationService';

const makeSelectAuthenticationState = () =>
  createSelector(
    selectAuthenticationService,
    ({ token, tokenType } = {}) => ({ token, tokenType })
  );

export default makeSelectAuthenticationState;
