import { loginPath, logoutPath, defaultPath } from '../../constants';

// these are paths that, if we see as a possible intended path, that we ill just replace with the
// default because they do not make sense in context
const NEVER_INTENDED_PATHS = new Set([ loginPath, logoutPath ]);

export function getIntendedPath(routerHistoryLocation) {
  if (!routerHistoryLocation) return null;

  const { state } = routerHistoryLocation;
  if (!state) return null;

  let { from } = state;
  while (from.state) {  // go to the first defined state and grab "from"
    ({ from } = from.state);
  }

  return NEVER_INTENDED_PATHS.has(from.pathname)
    ? defaultPath
    : from.pathname;
}

//
// getIntendedPath({
//   state: {
//     from:{
//       hash: '',
//       key: 's8dm8p',
//       pathname: '/login',
//       search: '',
//       state:{
//         from:{
//           hash: '',
//           pathname: '/workflows',
//           search: '',
//           state: undefined,
//         }
//       }
//     }
//   }
// }) // ?
