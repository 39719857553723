import { Registry, Service } from '@avicado/platform';
import { trim } from '@avicado/type';


const TWO_HOURS = 60 * 60 * 2
const DEFAULT_DOMAIN = process.env.REACT_APP_DOMAIN;

class Cookies {
  get all() {
    const pairs = document.cookie.split(';').map(v => v.split('='));
    // eslint-disable-next-line unicorn/no-array-reduce
    return pairs.reduce((p, [key, value] = []) => ({ ...p, [trim(key)]: value }), {});
  }

  getValue(name) {
    return this.all[name];
  }

  //=TWO_HOURS
  setValue(name, value, {
    secure = null,
    domain = DEFAULT_DOMAIN,
    maxAge,
    expires = null,
    samesite = 'strict',
  } = {}) {
    console.log('DEFAULT_DOMAIN:', DEFAULT_DOMAIN);
    console.log('REACT_APP_DOMAIN:', process.env.REACT_APP_DOMAIN);
    const options = {
      secure,
      domain: process.env.REACT_APP_DOMAIN,
      'max-age': maxAge,
      expires,
      samesite,
    };

    const optionValue = Object.keys(options)
      .map(key => options[key] ? this.optionValue(key, options[key]) : undefined)
      .filter(v => !!v).join('; ');
    const cookieValue = [`${name}=${value || ''}`, optionValue].join('; ');
    document.cookie = cookieValue;
  }

  deleteValue(name, options = {}) {
    this.setValue(name, null, { options, maxAge: 0, expires: new Date(0).toUTCString() });
  }

  optionValue(name, value) {
    const optionTypes = {
      secure: Boolean,
      domain: String,
      'max-age': Number,
      expires: String,
      samesite: String,
    };

    const type = optionTypes[name];
    if (type === Boolean) {
      return !!value ? name : null;
    }
    if (type === String) {
      return `${name}=${value}`;
    }
    // Number or default
    return `${name}=${value}`;
  }
}

class StorageClassPermission {
  /**
   *
   * @param name {string}
   * @param allowCookies {boolean}
   * @param secure {boolean|null}
   * @param domain {string|null}
   * @param maxAge {number|null}
   * @param expires {number|null}
   * @param samesite {string|null}
   */
  constructor(name, allowCookies, {
    secure = false,
    domain = null,
    maxAge = TWO_HOURS,
    expires = null,
    samesite = 'strict',
  } = {}) {
    this.name = name;
    this.allowCookies = allowCookies;
    this.cookieOptions = {
      secure,
      domain,
      maxAge,
      expires,
      samesite,
    };

    this.constructor.registry.setValue(name, this);
  }

  static get registry() {
    if (!this._r) {
      this._r = new Registry();
    }
    return this._r;
  }

  static create(name, allowCookies, options) {
    return new this(name, allowCookies, options);
  }
}

// TODO: enhance to allow classes of storage data so the permanent storage can be determined by
// user preferences
const DEFAULT_STORAGE_CLASS_NAME = 'default';

class StorageService extends Service {
  constructor(...args) {
    super(...args);
    this.cookies = new Cookies();
    this.memoryCache = new Registry();
    StorageClassPermission.create(DEFAULT_STORAGE_CLASS_NAME, false);
  }

  get name() {
    return 'storage';
  }

  get getters() {
    return [
      'all',
      'storageClassNames',
    ];
  }

  get currentProtocol() {
    const fullProto = window.location.protocol || document.location.protocol;
    return fullProto.replace(/^([a-z]+):/gi, '$1');
  }

  get publicMethods() {
    return {
      setValue: this.setValue,
      getValue: this.getValue,
      setStorageClassPermission: this.setStorageClassPermission,
      getStorageClass: this.getStorageClass,
    };
  }

  get storageClassNames() {
    return StorageClassPermission.registry.keys;
  }

  get all() {
    return { ...this.memoryCache.hash, ...this.cookies.all };
  }

  getStorageClass(name) {
    return StorageClassPermission.registry.valueFor(name);
  }

  /**
   *
   * @param storageClassName {string}
   * @param allowCookies {boolean}
   * @return {Promise<void>}
   */
  async setStorageClassPermission(storageClassName, allowCookies, options) {
    const previousClass = StorageClassPermission.registry.valueFor(storageClassName);
    StorageClassPermission.create(storageClassName, allowCookies, options);
    // NOTE: auto-registered when created

    if (!previousClass) {
      return;
    }

    if (options.secure && this.currentProtocol !== 'https') {
      // if this is true, we cannot write to cookies so... let's be able to not fail
      options.secure = false;
    }
    if (!previousClass.allowCookies && allowCookies) {
      // not allowed => allowed; copy memory to cookies

      const storageClassKeys = this.memoryCache.keys.filter(key => key.startsWith(`${storageClassName}.`));

      for (const key of storageClassKeys) {
        const value = await this.getValue(this.nameFor(key), storageClassName);

        this.cookies.setValue(key, value, options);
        this.memoryCache.deleteValue(key);
      }
    } else if (previousClass.allowCookies && !allowCookies) {
      // allowed => not allowed; copy cookies to memory and delete the cookies

      const allCookies = this.cookies.all;
      const storageClassKeys = Object.keys(allCookies)
        .filter(key => key.startsWith(`${storageClassName}.`));

      for (const key of storageClassKeys) {
        const value = await this.getValue(this.nameFor(key), storageClassName); // allCookies[key];

        this.memoryCache.setValue(key, { value, options });
        this.cookies.deleteValue(key);
      }


    } else {
      // some other change, recreate all the cookies to make it effective.  BUt we don't know
      //if the cookie was created by overriding the storage class, so... we can guess, I guess?
      // TODO: this
    }
  }

  async setValue(name, value, storageClassName, options = {}) {
    try {
      const sClass = StorageClassPermission.registry.valueFor(storageClassName)
        || StorageClassPermission.registry.valueFor(DEFAULT_STORAGE_CLASS_NAME);
      if (sClass.name === DEFAULT_STORAGE_CLASS_NAME) {
        this.log.warn(`Using the default storage class for ${name} because storage class '${storageClassName}' is undeclared`);
      }
      if ((sClass.cookieOptions.secure || options.secure) && this.currentProtocol !== 'https') {
        this.log.warn(`Using a secure token with an insecure protocol is incompatible.  Downgrading cookie security.`);
        options.secure = false;
      }

      const cookieName = this.cookieName(name, storageClassName);
      if (!sClass.allowCookies) {
        return this.memoryCache.setValue(cookieName, { value, options });
      }
      return this.cookies.setValue(cookieName, value, { ...sClass.cookieOptions, ...options });
    } catch (error) {
      this.log.error(error);
    }
  }

  async getValue(name, storageClass) {
    const cookieName = this.cookieName(name, storageClass);
    let result = this.cookies.getValue(cookieName);
    if (!result) {
      ({ value: result } = this.memoryCache.valueFor(cookieName) || {});
    }
    return result;
  }

  cookieName(name, storageClassName) {
    return `${storageClassName}.${name}`;
  }

  storageClassFor(cookieName = '') {
    if (!cookieName) {
      return null;
    }

    const [storageClassName = ''] = cookieName.split['.'];
    return storageClassName;
  }

  nameFor(cookieName = '') {
    if (!cookieName) {
      return null;
    }

    const [, name = ''] = cookieName.split('.');
    return name;
  }
}

StorageService.register();

export default StorageService;
