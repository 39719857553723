import { BaseRenderer, Platform } from '@avicado/platform';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import AppContainer from './app';

const Loader = () => <div>Loading...</div>;

class ReactRenderer extends BaseRenderer {
  static props() {
    return [
      Platform.getInstance(),
    ];
  }

  constructor(platform) {
    super();
    this.platform = platform;
  }

  renderInto(root) {
    // eslint-disable-next-line unicorn/prefer-query-selector
    const MOUNT_NODE = document.getElementById(root);
    // eslint-disable-next-line react/no-render-return-value
    return ReactDOM.render(this.render(), MOUNT_NODE);
  }

  //messages={this.props.messages[this.props.locale]}
  render() {
    const { platform } = this;

    return (
      <I18nextProvider i18n={platform.serviceFor('i18n')}>
        <Suspense fallback={<Loader />}>
          <AppContainer platform={platform} />
        </Suspense>
      </I18nextProvider>
    );
  }
}

export default ReactRenderer;
