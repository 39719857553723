import { declareAction, AsyncAction , util } from '@avicado/platform';

import { all, put, takeEvery } from 'redux-saga/effects';
import { UnloadApplication } from './unload-application';

// eslint-disable-next-line unicorn/prevent-abbreviations
export const UnloadApplications = declareAction(
  'application:system',
  'UnloadApps',
  class UnloadApps extends AsyncAction {
    *doStartAction(parameters) {
      // WARNING: deliberate rule break incoming (.target)
      const systemApp = util.applicationKey('system');
      const { [systemApp]: system } = this.client.events.target.state;

      const { dynamicApplications } = system;
      return yield { ...parameters, applications: [...new Set(dynamicApplications)] };
    }

    *doAction({ applications: apps = [], ...others }) {
      yield all(apps.map(
        name => put(UnloadApplication.perform({ name }))
      ));
      return { applications: apps, ...others };
    }
  },
  { effect: takeEvery }
);

export default UnloadApplications;
