import React from 'react';
import PropTypes from 'prop-types';
import { Platform, EVENT_TYPES } from '@avicado/platform';
import { isRequired } from '@avicado/util';
import { Log } from '@avicado/log';


function injectProps(element = isRequired('element'), props) {
  let { key, type: { name } = {} } = element;
  const { children, ...otherProps } = props;
  key = key || otherProps.id || name; // try to get something to use as a key, if it's not provided

  return React.cloneElement(element, { ...otherProps, key }, children);
}
//eslint-disable-next-line unicorn/prevent-abbreviations
class ApplicationStateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.log = Log.create('ApplicationStateContainer');

    const { events = [], noEvents = false } = props;
    const enableEvents = !noEvents;
    // When app or service has an event listed in 'events', re-render children
    if (enableEvents) {
      for (const event of events)  this.platform.events.on(event, () => this.forceUpdate());
    }
  }

  get platform() {
    return Platform.instance; // get the instance without creating it
  }

  get applications() {
    return this.client ? this.client.applications.values : this.platform.applications;
  }

  get services() {
    return this.client ? this.client.services : this.platform.services;
  }

  get client() {
    return this.platform.applicationFor(this.props.client);
  }

  get service() {
    return this.platform.serviceFor(this.props.service);
  }

  render() {
    const { children, events, ...myProps } = this.props;
    if (!children) return <div/>;

    const { service, client, platform } = this;
    const apps = this.applications.values;
    const services = this.services.values;
    return React.Children.toArray(children)
      .map(child => injectProps(child, { ...myProps, ...child.props, platform, clients: apps, applications: apps, services, client, service }));
  }
}

ApplicationStateContainer.propTypes = {
  events: PropTypes.array.isRequired,
  noEvents: PropTypes.bool.isRequired,
  client: PropTypes.string,
  service: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ApplicationStateContainer.defaultProps = {
  noEvents: false,
  events: [
    EVENT_TYPES.SERVICE_READY,
    EVENT_TYPES.APPLICATION_READY
  ], // events that trigger a re-render
};

export default ApplicationStateContainer;
