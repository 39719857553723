import { makeSelectIsAuthenticated } from '@avicado/authentication-app';
import { ApplicationStateContainer } from '@avicado/react-platform-components';
import { makeStyles } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PlatformHeaderContainer } from '../containers/platform-header-container';
import PlatformMenuContainer from '../containers/platform-menu-container';
import { SnackbarNotifierContainer } from '../containers/snackbar-notifier-container';
import theme from '../theme';
import ApplicationContent from './application-content';
import { PlatformFooter } from './platform-footer';


const useStyles = makeStyles((theme) => ({
  layout: {
    display: 'flex',
  },
  rhs: {
    // set main container to fill page width minus open/closed menu width
    // open/closed menu width pulled from styles in components/main-menu.js
    width: ({ menuOpen }) => menuOpen ? window.innerWidth - 240 : window.innerWidth - (theme.spacing(7) + 1),
    left: theme.spacing(7) + 1,
    flexGrow: 1,
  },
}));

function LayoutInt(props) {
  const { isAuthenticated } = props;
  const [ menuOpen, setMenuOpen ] = useState(false);
  const classes = useStyles({ ...props, menuOpen });
  const toggleMenu = () => setMenuOpen(!menuOpen);
  return (
    <div className={`layout ${classes.layout}`}>
      <ApplicationStateContainer>
        <PlatformMenuContainer menuOpen={menuOpen} onToggleMenu={toggleMenu} />
      </ApplicationStateContainer>

      <div className={classes.rhs}>
        <ApplicationStateContainer>
          <PlatformHeaderContainer key="app.header" menuOpen={menuOpen} onToggleMenu={toggleMenu} isAuthenticated={isAuthenticated} />
          <ApplicationContent key="app.content" isAuthenticated={isAuthenticated}/>
          <PlatformFooter key="app.footer" isAuthenticated={isAuthenticated}/>
        </ApplicationStateContainer>
      </div>
      <SnackbarProvider domRoot={document.querySelector("div.layout")}>
        <SnackbarNotifierContainer/>
      </SnackbarProvider>
    </div>
  );
}

LayoutInt.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: makeSelectIsAuthenticated(),
});


function mapDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export const Layout = compose(withConnect, memo)(LayoutInt);
export default Layout;
