import { isRequired } from '@avicado/platform';
import { ALPHA_NUM_DIGITS, Random } from '@avicado/util';
import * as actions from '../actions';
import { withKey, withMessage, withoutKey } from '../utils';


export default {
  [actions.EnqueueSnackbar.type]: (action, state, draft) => {
    const {
      type,
      key = Random.id(16, ALPHA_NUM_DIGITS),
      message = isRequired('message'),
      variant = isRequired('variant'),
      ...notification
    } = action;


    // prevent duplicate messages
    const previousNotification = draft.snackbarNotifications.some(withMessage(message));
    if (!previousNotification) {

      draft.snackbarNotifications.push(
        {
          key,
          message,
          variant,
          state: 'queued',
          ...notification,
        },
      );
    }
  },

  [actions.CloseSnackbar.type]: (action, state, draft) => {
    const { key, dismissAll } = action;
    // eslint-disable-next-line unicorn/no-array-for-each
    draft.snackbarNotifications.forEach((n, i) => {
      if (dismissAll || n.key === key) {
        draft.snackbarNotifications[i].dismissed = true;
        draft.snackbarNotifications[i].state = 'closed';
      }
    });
  },

  [actions.RemoveSnackbar.type]: (action, state, draft) => {
    const { key } = action;
    draft.snackbarNotifications = (draft.snackbarNotifications || []).filter(withoutKey(key));
  },

  [actions.AddSnackbarDisplayed.type]: (action, state, draft) => {
    const { key } = action;
    const notification = draft.snackbarNotifications.find(withKey(key));
    if (!notification) return;

    draft.snackbarNotifications = [
      ...draft.snackbarNotifications.filter(withoutKey(key)),
      { ...notification, state: 'visible' },
    ];
  },
};
