import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectIsAuthenticated } from '@avicado/authentication-app';
import { MainMenu } from '../components/main-menu';

function PlatformMenuContainer({ platform, isAuthenticated, menuOpen, onToggleMenu, ...others } = {}) {
  if ( !isAuthenticated ) return null;

  return (
    <MainMenu
      menu="main"
      platform={platform}
      isAuthenticated={isAuthenticated}
      open={menuOpen}
      onToggleMenu={onToggleMenu}
    />
  );
}

PlatformMenuContainer.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  platform: PropTypes.object.isRequired,
  menuOpen: PropTypes.bool,
  onToggleMenu: PropTypes.func,
};

PlatformMenuContainer.defaultProps = {
  menuOpen: false,
  onToggleMenu: () => {},
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: makeSelectIsAuthenticated(),
});

const withConnect = connect(
  mapStateToProps,
);

export default compose(
  withConnect,
  memo,
)(withRouter(PlatformMenuContainer));
