import { Service } from '@avicado/platform';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import * as resources from '../../translations';


export const DEFAULT_LOCALE = 'en';

class I18nService extends Service {
  constructor(...args) {
    super(...args);

    // eslint-disable-next-line no-undef
    const [language, region] = window.navigator.language.split('-');
    this._language = language;
    this._region = region;
  }

  get language() {
    return this._language;
  }

  get getters() {
    return [
      'language',
      'i18n',
      'provider',
      't'
    ];
  }

  get publicMethods() {
    return {
      translate: this.translate,
      loadTranslation: this.loadTranslation,
      initializeProvider: this.initializeProvider,
    };
  }

  get name() {
    return 'i18n';
  }

  get middleware() {
    return [
    ];
  }

  get provider() {
    return this.i18n;
  }

  async initialize() {
    await super.initialize();
    this.i18n = i18n;
    await this.i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next);

    await this.initializeProvider();
  }

  async initializeProvider() {
    const { applications } = this.client.platform;
    const namespaces = [
      'common', // common core
      ...applications.keys.map(slug => `application:${slug}`), // all applications
    ];

    this.t = await this.i18n
      .init({
        resources,
        lng: this.language,
        fallbackLng: DEFAULT_LOCALE,
        defaultNS: namespaces,
        nsSeparator: '::',
        keySeparator: '.',
        debug: true,
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
      });
    return this;
  }

  async loadTranslation(appOrService, language, bundle) {
    await this.i18n.addResourceBundle(language, appOrService.key, bundle);
    await this.initializeProvider();
    return this;
  }

  async unloadTranslation(appOrService, language) {
    await this.i18n.removeResourceBundle(language, appOrService.key);
    return this;
  }

  async ready() {
    for (const language of Object.keys(resources)) {
      this.i18n.addResourceBundle(language, 'common', resources[language], true);
    }

    super.ready();
  }

  /**
   * Translate a key (and possibly a default value) to the current language.
   * @param key {string|string[]} a single or list of keys (in order or priority) to show.  If
   * the first value isn't found, the second will be used, and so on.
   * @param defaultValue {string} a value to use if the key or keys are not found
   * @returns {string} A translated string or the default value
   */
  translate(key, defaultValue) {
    return this.t(key, defaultValue);
  }
}

// eslint-disable-next-line no-undef
window.i18n = i18n;

I18nService.register();

export default I18nService;
