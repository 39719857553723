import { util } from '@avicado/platform';
import { createSelector } from 'reselect';


export const selectProfileApp = (state = {}) => state[util.applicationKey('profile')];

export const makeSelectProfile = () =>
  createSelector(
    selectProfileApp,
    ({ profile } = {}) => profile
  );


