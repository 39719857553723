import { put, takeLatest } from 'redux-saga/effects';
import { AsyncObserver, declareObserver, Platform, EVENT_TYPES } from '@avicado/platform';
import { ApplicationStartupComplete } from './application-startup-complete';
import { SystemReady } from './system-ready';

// eslint-disable-next-line unicorn/prevent-abbreviations
export const ApplicationStartupCompleteWatcher = declareObserver(
  'application:system',
  'AppStartupCompleteWatcher',
  class AppStartupCompleteWatcher extends AsyncObserver {
    *invoke(...args) {
      const events = Platform.serviceFor('events');
      const { 'application:system': system, 'service:authentication': auth } = events.state;
      const { applicationsReady: appsReady = false, servicesReady = false, dynamicApplications: dynamicApps = [] } = system;
      const { isAuthenticated = false } = auth

      if (appsReady && servicesReady && isAuthenticated && dynamicApps.length > 0) {
        yield put(SystemReady.perform())
      }
    }
  },
  { effect: takeLatest, pattern: ApplicationStartupComplete.type }
);


