import { AsyncObserver, declareObserver, EVENT_TYPES } from '@avicado/platform';
import { put, takeLatest } from 'redux-saga/effects';
import { ApplicationStartupComplete } from './application-startup-complete';


// eslint-disable-next-line unicorn/prevent-abbreviations
export const ApplicationReadyWatcher = declareObserver(
  'application:system',
  'AppReadyWatcher',
  class AppReadyWatcher extends AsyncObserver {
    *invoke() {
      yield put(ApplicationStartupComplete.perform(this.action))
    }
  },
  { effect: takeLatest, pattern: EVENT_TYPES.APPLICATION_READY }
);


