import { isNullish } from '@avicado/util';
import { createSelector } from 'reselect';
import { selectCurrentOrganization } from './select-current-organization';
import { selectSystemApp } from './select-system-app';


export const selectSnackbarDisplayed = createSelector(
  selectSystemApp,
  ({ snackbarNotifications } = {}) => {
    return (snackbarNotifications || []).filter(({ state }) => state === 'visible')
  },
)
