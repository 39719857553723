import { Function } from '@avicado/platform';
import {
  AutoFormControl,
  AutoFormErrors,
  CustomAutoForm, FontAwesomeIcon,
  Img,
  Section,
  Translate,
} from '@avicado/react-platform-components';
import { Button, Paper, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { login as loginSchema } from '../../models';


const styles = theme => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexAlign: 'center',
    color: theme.palette.primary.main,
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    "& > *": {
      margin: '0.15em'
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexAlign: 'center',
  },
  container: {
    display: 'flex',
    flexAlign: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing(3)}px 0`,
    maxWidth: 500,
    margin: '0 auto',
  },
  input: {
    [theme.breakpoints.up('xs')]: {
      width: 200,
    },
    [theme.breakpoints.up('sm')]: {
      width: 400,
    },
    width: 500,
  },
  logo: {
    width: 200,
  },
});


class LoginFormInt extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    onSubmit: PropTypes.func,
    onForgotPassword: PropTypes.func,
    errors: PropTypes.object,
  };

  static defaultProps = {
    onSubmit: Function.identity,
    onForgotPassword: Function.identity,
    errors: {},
  };

  state = {
    email: '',
    password: '',
    nextPath: '',
  };

  constructor(props, ...others) {
    super(props, ...others);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      email: '',
      password: '',
      nextPath: props.nextPath,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { nextPath } = props;
    if (nextPath !== state.nextPath) {
      return { nextPath: props.nextPath };
    }
    return null;
  }

  onSubmit() {
    this.props.onSubmit(this.state);
  }

  onChange(prop, value) {
    this.setState({ [prop]: value });
  }

  render() {
    const { classes, errors, nextPath, onForgotPassword } = this.props;
    const { email, password } = this.state;
    const doc = { email, password, nextPath };

    return (
      <Paper elevation={2} className={classes.container}>
        <CustomAutoForm
          doc={doc}
          schema={loginSchema}
          onSubmit={this.onSubmit}
          onChange={this.onChange}
        >
          <Section className={classes.section} component="div">
            <Section className={classes.sectionRow} component="div">
              <FontAwesomeIcon name="right-to-bracket" size="large" weight="solid" />
              <Typography
                component="h1"
                style={{ fontSize: '2rem', fontWeight: 'bold' }}
              >Login
              </Typography>
            </Section>
          </Section>
          <Section className={classes.section} component="div">
            <AutoFormErrors errors={errors} />
            <AutoFormControl prop="email" className={classes.input} autoFocus />
            <AutoFormControl prop="password" className={classes.input} />
            <AutoFormControl prop="nextPath" hidden />
            <Button />
          </Section>
          <Section className={classes.buttons} component="div">
            <Button color="primary" type="submit" variant="contained" data-test="submit">
              <Translate value="login">Submit</Translate>
            </Button>
            <Button color="default" type="button" onClick={onForgotPassword} data-test="forgotPassword">
              <Translate value="forgotPassword">Forgot Password</Translate>
            </Button>
          </Section>
        </CustomAutoForm>
      </Paper>
    );
  }
}


export const LoginForm = withStyles(styles, { withTheme: true })(
  LoginFormInt
);
export default LoginForm;
