import { AsyncObserver, declareObserver, Platform } from '@avicado/platform';
import { put, takeLatest } from 'redux-saga/effects';
import { ServiceStartupComplete } from './service-startup-complete';
import { SystemReady } from './system-ready';


export const ServiceStartupCompleteWatcher = declareObserver(
  'application:system',
  'ServiceStartupCompleteWatcher',
  class ServiceStartupCompleteWatcher extends AsyncObserver {
    *invoke(...args) {
      const events = Platform.serviceFor('events');
      const { 'application:system': system, 'service:authentication': auth } = events.state;
      const { applicationsReady: appsReady = false, servicesReady = false, dynamicApplications: dynamicApps = [] } = system;
      const { isAuthenticated = false } = auth

      if (appsReady && servicesReady && isAuthenticated && dynamicApps.length > 0) {
        yield put(SystemReady.perform())
      }
    }
  },
  { effect: takeLatest, pattern: ServiceStartupComplete.type }
);


