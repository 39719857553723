import { whenServiceReady } from '@avicado/platform';

whenServiceReady('dynamicapplications',
  dynAppSvc => {
    dynAppSvc.addApplication('authentication', async () => import('@avicado/authentication-app'));
    dynAppSvc.addApplication('dashboard', async () => import('@avicado/dashboard-app'));
    dynAppSvc.addApplication('profile', async () => import('@avicado/profile-app'));
    dynAppSvc.addApplication('system', async () => import('@avicado/system-app'));
    dynAppSvc.addApplication('workflow', async () => import('@avicado/workflow-app'));
    dynAppSvc.addApplication('task', async () => import('@avicado/task-app'));
    dynAppSvc.addApplication('form', async () => import('@avicado/form-app'));
    dynAppSvc.addApplication('user-management', async () => import('@avicado/user-management-app'));
  },
  error => console.error('Dynamic Applications Service did not start:', error.message)
);

