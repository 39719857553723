import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Logout as LogoutAction } from '../actions';
import { defaultPath } from '../constants';
import makeSelectIsAuthenticated from '../selectors/isAuthenticated';

function Logout({ logout, isAuthenticated, location } = {}) {
  if (isAuthenticated) logout();

  return  <Redirect to={{ pathname: defaultPath, state: { from: location } }}/>
}

Logout.propTypes = {
  logout: PropTypes.func,
};

Logout.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: makeSelectIsAuthenticated(),
});


export function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(LogoutAction.perform()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(withRouter(Logout));
