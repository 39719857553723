import { first } from '@avicado/type';
import * as actions from '../actions';


export default {
  [actions.LoadOrganizations.type]: (action, state, draft) => {
    const { type, ...rest } = action;
    const { organizations, currentOrganizationId, currentProjectId } = state;
    draft.organizations = rest[0] ? Object.values(rest) || [] : organizations;
    // auto-select the "all" if there are many or the first (only) if there is one

    const organization = first(draft.organizations);
    // organization
    const { id: organizationId = null } = draft.organizations.length === 1 ? organization : {};
    const { projects = [] } = organization || {};

    // auto-select the "all" projects
    const { id: projectId = null } = {};

    // draft.organizations = organizations;
    draft.projects = [...(projects || [])];
    draft.currentOrganizationId = currentOrganizationId ? currentOrganizationId : organizationId;
    draft.currentProjectId = currentProjectId ? currentProjectId : projectId;
  },

  [actions.SelectOrganization.type]: (action, state, draft) => {
    const { type, ...rest } = action;
    const { organizations = [], currentOrganizationId } = state;
    const organizationId = action.id || currentOrganizationId;
    const organization = organizations.find(({ id }) => id === organizationId);
    const { projects = [] } = organization || {};
    const projectId = null;

    draft.projects = [...(projects || [])];
    draft.currentOrganizationId = organizationId === 'null' ? null : organizationId;
    draft.currentProjectId = projectId;
  },

  [actions.LockOrganization.type]: (action, state, draft) => {
    draft.organizationIsLocked = true;
  },
  [actions.UnlockOrganization.type]: (action, state, draft) => {
    const { lock = true } = action;
    draft.organizationIsLocked = false;
  },
};
