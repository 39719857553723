import { Log } from '@avicado/log';
import { AsyncAction, declareAction, unloadApplication } from '@avicado/platform';
import { call, takeEvery } from 'redux-saga/effects';

const log = Log.create('UnloadApplication');

// eslint-disable-next-line unicorn/prevent-abbreviations
export const UnloadApplication = declareAction(
  'application:system',
  'UnloadApp',
  class UnloadApp extends AsyncAction {
    *doAction({ name, ...others }) {
      yield call(unloadApplication, name);

      return { name, ...others };
    }
  },
  { effect: takeEvery }
);
export default UnloadApplication;
