import { makeSelectIsAuthenticated } from '@avicado/authentication-app';
import { useMenus } from '@avicado/react-platform-components';
import { first } from '@avicado/type';
import { isNullish } from '@avicado/util';
import {
  Avatar,
  Breadcrumbs,
  makeStyles,
  MenuItem,
  Select,
  Toolbar,
  Typography,
  useTheme,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { NavigateNext, Settings } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectProfile } from '../selectors';
import { HeaderMenu } from './header-menu';


const useStyles = makeStyles(theme => ({
  header: {
    // height: '64px',
    color: '#ffffff',
    fontSize: '24px',
    fontWeight: '700',
    // padding: '4px',
  },
  headerButton: {
    color: '#ffffff'
  },
  companyName: {
    color: '#ffffff',
    textDecoration: 'none',
  },
  breadcrumbs: {
    color: 'white',
    fontSize: '24px',
    fontWeight: '700',
  },
  outlined: {
    padding: `${theme.spacing(1)} 0 0 0`,
    color: 'white',
    margin: 0,
    // padding: 0,
    border: 'none',
  },
  root: {
    padding: `${theme.spacing(1)}px 0 0 0`,
  },
  icon: {
    color: '#bdbdbd',
  }
}));

const HeaderSelect = ({ classes, collection, selectedItem, onSelect, keyPrefix, isLocked }) => {
  let inner;
  if (isNullish(collection) || !Array.isArray(collection)) {
    inner = collection;
  } else if (isLocked) {
    // NOTE: this assumes the id is always a string, which I think is a requirement of the MUI
    //  select anyway
    const item = collection.find(({ id }) => id === `${selectedItem}`);
    if (item) ({ name: inner } = item);
  } else if (collection.length === 1) {
    inner = first(collection).name;
  } else {
    inner = <Select
      disableUnderline
      onChange={event => onSelect(event.target.value)}
      value={`${selectedItem}`}
      classes={{ select: classes.header, root: classes.root, icon: classes.icon }}
      data-test={`header-select-items-${keyPrefix}`}
    >
      { collection.map(({ id, name }, index) => <MenuItem data-test={`item-select-${index}`} value={`${id}`} key={`${keyPrefix}${id}`}>{name}</MenuItem>) }
    </Select>
  }

  return <Typography component="div" className={classes.header} >{inner}</Typography>
};

HeaderSelect.propTypes = {
  classes: PropTypes.object,
  collection: PropTypes.array,
  selectedItem: PropTypes.string,
  onSelect: PropTypes.func,
  keyPrefix: PropTypes.string,
  isLocked: PropTypes.bool,
};


const OrganizationSelect = ({ classes, organizations, selectedOrganization, onSelectOrganization, isLocked }) => {
  const collection = organizations && organizations.length === 1
    ? organizations
    : [{ id: 'null', name: 'All Organizations' }, ...organizations];

  return <HeaderSelect
    isLocked={isLocked}
    classes={classes}
    collection={collection}
    selectedItem={selectedOrganization}
    onSelect={onSelectOrganization}
    keyPrefix="o"/>
};

OrganizationSelect.propTypes = {
  classes: PropTypes.object,
  organizations: PropTypes.array,
  selectedOrganization: PropTypes.string,
  onSelectOrganization: PropTypes.func,
  isLocked: PropTypes.bool,
};


const ProjectSelect = ({ classes, projects, selectedProject, onSelectProject, isLocked }) => {
  const collection = [{ id: 'null', name: 'All Projects' }, ...(projects || [])];

  return <HeaderSelect
    isLocked={isLocked}
    classes={classes}
    collection={collection}
    selectedItem={selectedProject}
    onSelect={onSelectProject}
    keyPrefix="p"/>
};

ProjectSelect.propTypes = {
  classes: PropTypes.object,
  projects: PropTypes.array,
  selectedProject: PropTypes.string,
  onSelectProject: PropTypes.func,
  isLocked: PropTypes.bool,
};

const OrgAndProjectHeader = ({
  classes,
  selectedOrganization,
  selectedProject,
  selectProject,
  selectOrganization,
  projects,
  organizations,
  organizationIsLocked,
  projectIsLocked,
  ...props
})=> (
  <Breadcrumbs
    data-test="organization-project-select"
    separator={<NavigateNext fontSize="small"/>}
    classes={{ root: classes.breadcrumbs }}>
    <OrganizationSelect
      organizations={organizations}
      selectedOrganization={selectedOrganization}
      classes={classes}
      onSelectOrganization={selectOrganization}
      isLocked={organizationIsLocked}
      {...props}
    />
    <ProjectSelect
      projects={projects}
      selectedProject={selectedProject}
      onSelectProject={selectProject}
      classes={classes}
      isLocked={projectIsLocked}
      {...props}
    />
  </Breadcrumbs>
)

OrgAndProjectHeader.propTypes = {
  classes: PropTypes.object,
  organizations: PropTypes.array,
  selectedOrganization: PropTypes.string,
  selectOrganization: PropTypes.func,
  projects: PropTypes.array,
  selectedProject: PropTypes.string,
  selectProject: PropTypes.func,
  organizationIsLocked: PropTypes.bool,
  projectIsLocked: PropTypes.bool,
};

OrgAndProjectHeader.defaultProps = {
  organization: { projects: [] },
  organizationIsLocked: false,
  projectIsLocked: false,
}

const FillAvailableSpace = () => <div style={{ flexGrow: 1 }} />

function PlatformHeaderInt(props) {
  const { isAuthenticated, profile = {}, organizations, projects, organizationId, projectId, selectProject, selectOrganization, organizationIsLocked, projectIsLocked } = props;
  let { givenName, familyName } = profile;
  if (isNullish(givenName)) givenName = 'Anonymous';
  if (isNullish(familyName)) familyName ='User';

  const [firstInitial] = givenName;
  const [lastInitial] = familyName;

  const [accountMenus, profileMenus] = useMenus('account', 'profile');
  const classes = useStyles(props);
  const Account = <Avatar>{`${firstInitial}${lastInitial}`}</Avatar>;

  // Quickfix for header color being overwritten due to JSS injection bugs
  const { palette: { primary } } = useTheme();
  const inlineHeaderStyles = { backgroundColor: primary.main };

  return (
    <AppBar position="static" color="primary" elevation={0} className={classes.header} style={inlineHeaderStyles}>
      <Toolbar data-test="header-toolbar">
        {
          isAuthenticated &&
          <OrgAndProjectHeader
            classes={classes}
            organizations={organizations}
            projects={projects}
            selectedOrganization={organizationId}
            selectedProject={projectId}
            selectProject={selectProject}
            selectOrganization={selectOrganization}
            organizationIsLocked={organizationIsLocked}
            projectIsLocked={projectIsLocked}
          />
        }
        <FillAvailableSpace />
        { /* Right Side Menus */}
        {isAuthenticated && <HeaderMenu buttonContent={<Settings/>} menus={profileMenus}/>}
        {isAuthenticated && <HeaderMenu buttonContent={Account} menus={accountMenus}/>}
      </Toolbar>
    </AppBar>
  )
}

PlatformHeaderInt.propTypes = {
  isAuthenticated: PropTypes.bool,
  profile: PropTypes.object,
  classes: PropTypes.string,
  organizations: PropTypes.array,
  selectedOrganization: PropTypes.string,
  selectOrganization: PropTypes.func,
  projects: PropTypes.array,
  selectedProject: PropTypes.string,
  selectProject: PropTypes.func,
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  organizationIsLocked: PropTypes.bool,
  projectIsLocked: PropTypes.bool,
};

PlatformHeaderInt.defaultProps = {
  profile: {},
  organizationIsLocked: false,
  projectIsLocked: false
}
const mapStateToProps = createStructuredSelector({
  isAuthenticated: makeSelectIsAuthenticated(),
  profile: makeSelectProfile(),
});

const withConnect = connect(mapStateToProps, {})

export const PlatformHeader = withConnect(PlatformHeaderInt);
export default PlatformHeader;
