import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector, createStructuredSelector } from 'reselect';
import selectAuthenticationService from '../selectors/authenticationService';
import makeSelectAuthenticationState from '../selectors/authenticationToken';
import makeSelectIsAuthenticated from '../selectors/isAuthenticated';
import { AuthenticationWidget } from '../components/authentication-widget';
import makeSelectRefreshTime from '../selectors/refreshTime';

function AuthenticationWidgetContainer(props) {
  return <AuthenticationWidget {...props}/>
}

const makeSelectAccessToken = () =>
  createSelector(
    selectAuthenticationService,
    ({ accessToken = null } = {}) => accessToken);
const makeSelectRefreshToken = () =>
  createSelector(
    selectAuthenticationService,
    ({ refreshToken = null } = {}) => refreshToken);


const mapStateToProps = createStructuredSelector({
  isAuthenticated: makeSelectIsAuthenticated(),
  state: makeSelectAuthenticationState(),
  accessToken: makeSelectAccessToken(),
  refreshToken: makeSelectRefreshToken(),
  refreshTime: makeSelectRefreshTime(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(AuthenticationWidgetContainer);

