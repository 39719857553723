import { declareAction, SimpleAction } from '@avicado/platform';
import { isRequired } from '@avicado/util';


export default declareAction(
  'application:authentication',
  'ChangeLoginComponent',
  class ChangeLoginComponent extends SimpleAction {
    * doValidate({
      index = isRequired('index'), // eslint-disable-line no-unused-vars
    }) {}
  }
)
