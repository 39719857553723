import React from 'react';
import { ProgressBar } from './progress-bar';

const roundToDigit = (v, n = 0) => {
  const p = Math.pow(10, n); // ?
  return Math.round(v * p) / p;
}

export function AuthenticationWidget(props) {
  const { client, state: authenticationState } = props;

  const state = JSON.parse(client.authentication.serializableState);

  const { token: { createdAt, expires, refreshToken } = {}, resourceToken } = state;
  const { tokenType, token: accessToken } = authenticationState;
  const now = Date.now();
  const ttl = (expires - createdAt) / 1000;
  const expiresIn = (expires - now) / 1000;
  const percent = Math.round(expiresIn / ttl * 100);

  return (
    <ul style={{ overflowWrap: 'break-word' }}>
      <li>Token Type: {tokenType}</li>
      <li>Access Token: {accessToken}</li>
      <li>Refresh Token: {refreshToken}</li>
      <li>Resource Token: {resourceToken}</li>
      <li>Created At: {new Date(createdAt).toString()} ({createdAt})</li>
      <li style={{ overflow: 'nowrap' }}>Expires In: {roundToDigit(expiresIn/60, 1)}m (~{roundToDigit(expiresIn,0)}s) <ProgressBar value={percent} width='150px' height={'16px'}/></li>
      <li>Expires: {new Date(expires).toString()} ({expires})</li>
    </ul>
  )
}

export default AuthenticationWidget;
