export const scope = 'models.forms.login';

// please note that this file is in a different format from most of the i18n messages
// simpl-schema uses js-message-box (https://github.com/aldeed/js-message-box)
const messages = {
  en: {
    [`${scope}.minAlpha`]: '{{label}} must contain at least 2 alphabetic characters',
    [`${scope}.minNumber`]: '{{label}} must contain at least 2 numbers',
    [`${scope}.minSpecial`]: '{{label}} must contain at least 1 special character',
    [`${scope}.notEmail`]: '{{label}} cannot match the Email address',
  },
};

export default messages;
