import React from 'react';
import { NavLink } from 'react-router-dom';

//https://material-ui.com/components/buttons/#third-party-routing-library
// The usage of React.forwardRef will no longer be required for react-router-dom v6.
// see https://github.com/ReactTraining/react-router/issues/6056

// eslint-disable-next-line react/display-name
export const NavLinkRef = React.forwardRef((props, ref) => (
  <NavLink innerRef={ref} activeClassName="selected" {...props} />
));
