import { AsyncAction, declareAction, loadApplication } from '@avicado/platform';
import { call, takeEvery } from 'redux-saga/effects';


// eslint-disable-next-line unicorn/prevent-abbreviations
export const LoadApplication = declareAction(
  'application:system',
  'LoadApp',
  class LoadApp extends AsyncAction {
    *doAction({ key, name, ...others }) {
      const dynAppSvc = this.client.serviceFor('dynamicapplications');
      const loader = dynAppSvc.loaderFor(name);

      // adjust how we call loadApplication, based on the loader format

      if (!loader) { // necessary because typeof null === 'object
        yield call(loadApplication, name);
      } else if (typeof loader === 'object') {
        const { package: pkg, member = null } = loader;
        yield call(loadApplication, pkg, member);
      } else {
        yield call(loadApplication, name, loader);
      }

      return { name, key, ...others };
    }
  },
  { effect: takeEvery }
);
export default LoadApplication;
