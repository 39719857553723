import { gql } from '@apollo/client';
import { declareAction, makeMutationAction } from '@avicado/platform';


const MUTATION = gql`
  mutation SetApplicationData($application: String!, $data: JSON!){
    setApplicationData(
      key: $application,
      data: $data,
      forUser: true
    ) {
      key
      data
    }
  }
`;

// eslint-disable-next-line unicorn/prevent-abbreviations
export const SetUserApplicationPreferences = declareAction(
  'application:system',
  'SetUserApplicationPreferences',
  makeMutationAction(
    'SetUserApplicationPreferences',
    MUTATION,
    {}
  )
);
export default SetUserApplicationPreferences;
