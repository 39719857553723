import { Platform } from '@avicado/platform';
import { createSelector } from 'reselect';
import selectAuthenticationService from './authenticationService';
import { codeToMessage } from '../util/code-to-message';


const makeSelectAuthLoginError = () =>
  createSelector(
    selectAuthenticationService,
    authState => {
      if (!authState.error) return null;
      return codeToMessage(authState.error.code);
    }
  );

export default makeSelectAuthLoginError;
