import makeConnectedContainer from '@avicado/react-connected-container';
import { actions, selectors } from '@avicado/system-app';
import React from 'react';
import { PlatformHeader } from '../components';


const  {
  selectOrganizationIsLocked,
  selectProjectIsLocked,
  selectCurrentOrganizationId,
  selectCurrentProjectId,
  selectOrganizations,
  selectProjects
} = selectors;
const {
  SelectProject,
  SelectOrganization,
  LoadOrganizations,
  LoadProjects
} = actions;

export const PlatformHeaderContainer = makeConnectedContainer(
  'PlatformHeaderContainer',
  {
    state: {
      organizationId: selectCurrentOrganizationId,
      projectId: selectCurrentProjectId,
      organizations: selectOrganizations,
      projects: selectProjects,
      organizationIsLocked: selectOrganizationIsLocked,
      projectIsLocked: selectProjectIsLocked,
    },
    dispatch: {
      selectOrganization: SelectOrganization.dispatchWith('id'),
      selectProject: SelectProject.dispatchWith('id'),
    },
  },
  props => {
    return <PlatformHeader {...props}/>
  }
);
