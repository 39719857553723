import { Service } from '@avicado/platform';
import { connectRouter, routerMiddleware } from 'connected-react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';

class RouterService extends Service {
  constructor(...args) {
    super(...args);

    this.history = createBrowserHistory();
    this.routerMiddleware = routerMiddleware(this.history);
    this.connectRouter = connectRouter(this.history);
  }

  get key() {
    return 'router';
  }

  get getters() {
    return [
      'history',
    ]
  }

  get name() {
    return 'router';
  }

  reducer(state, action) {
    return this.connectRouter(state, action);
  }

  get middleware() {
    return [
      this.routerMiddleware,
    ];
  }
}

RouterService.register();

export default RouterService;
