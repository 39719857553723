import { EVENT_TYPES } from '@avicado/platform';
import * as actions from '../actions';
import { wholeCollectionIsReady } from '../utils';


export default {
  [EVENT_TYPES.PLATFORM_INIT]: (action, state, draft) => {
    draft.platform = 'init';
    draft.systemReady = false;
    draft.servicesReady = false;
    draft.applicationsReady = false;
  },
  [EVENT_TYPES.PLATFORM_READY]: (action, state, draft) => {
    draft.platform = 'ready';
  },
  [EVENT_TYPES.SERVICE_REGISTER]: (action, state, draft) => {
    draft.services[action.slug] = 'register';
    draft.servicesReady = false;
    draft.systemReady = false;
  },
  [EVENT_TYPES.SERVICE_READY]: (action, state, draft) => {
    draft.services[action.slug] = 'ready';
  },
  [actions.ServiceStartupComplete.type]: (action, state, draft) => {
    draft.servicesReady = wholeCollectionIsReady(state.services);
  },
  [EVENT_TYPES.SERVICE_INIT]: (action, state, draft) => {
    draft.services[action.slug] = 'shutdown';
    draft.servicesReady = false;
    draft.systemReady = false;
  },
  [EVENT_TYPES.SERVICE_SHUTDOWN]: (action, state, draft) => {
    draft.services[action.slug] = 'terminated';
  },

};
