import { declareAction, AsyncObserver , ApplicationActionName } from '@avicado/platform';

import { call } from 'redux-saga/effects';

export default declareAction(
  'application:authentication',
  'Refresh',
  class Refresh extends AsyncObserver {
    *invoke(args) {
      const authentication = this.client.authentication;
      const storage = this.client.serviceFor('storage');

      yield call(storage.setValue, 'auth', authentication.serializableState, 'authentication', { secure: true });

      return args;
    }
  },
  { pattern: ApplicationActionName('authentication')('Refresh') }
);


