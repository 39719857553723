import { DropdownButton } from '@avicado/react-platform-components';
import { Divider, ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import React from 'react';
import { NavLinkRef } from './nav-link-ref';
import { splitEntries } from './util/menu';
import PropTypes from 'prop-types';


const toMenuItem = m => (
  <MenuItem key={m.path} component={NavLinkRef} to={m.path}>
    <ListItemIcon>{m.icon}</ListItemIcon>
    <Typography>{m.title}</Typography>
  </MenuItem>
);

export const HeaderMenu = ({ buttonContent, menus }) => {
  const [before, after] = splitEntries(menus);

  const entries = [
    ...before.map((element) => toMenuItem(element)),
    after.length > 0 ? <Divider key="header-divider"/> : null,
    ...after.map((element) => toMenuItem(element)),
  ].filter(Boolean);

  return menus && (
    <DropdownButton
      arrow={false} buttonVariant="text" buttonContent={buttonContent}
      data-test="hamburger">
      {entries}
    </DropdownButton>
  );
};

HeaderMenu.propTypes = {
  buttonContent: PropTypes.node,
  menus: PropTypes.object,
}
