import * as actions from '../actions';


export default {
  [actions.ApplicationPreferences.type]: (action, state, draft) => {
    draft.preferences[action.key] = action.data;
  },
  [actions.ApplicationPreferences.types.changed]: (action, state, draft) => {
    draft.preferences[action.key] = action.data;
  },
};
