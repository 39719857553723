import { Log } from '@avicado/log';
import makeConnectedContainer from '@avicado/react-connected-container';
import { Button, useService } from '@avicado/react-platform-components';
import { actions as systemActions, selectors as systemSelectors } from '@avicado/system-app';
import { Close } from '@material-ui/icons';
import { withSnackbar } from 'notistack';
import React from 'react';


const log = Log.create('SnackbarContainer');

export const SnackbarNotifierContainer = makeConnectedContainer(
  'SnackbarNotifierContainer',
  {
    state: {
      notifications: systemSelectors.selectSnackbarNotifications,
    },
    dispatch: {
      // addSnackbar: systemActions.EnqueueSnackbar.dispatchWith('message', 'variant'),
      removeSnackbar: systemActions.RemoveSnackbar.dispatchWith('key'),
      addDisplayed: systemActions.AddSnackbarDisplayed.dispatchWith('key'),
      closeSnackbar: systemActions.CloseSnackbar.dispatchWith('key'),
    },
  },
  withSnackbar(props => {
    const {
      removeSnackbar,
      addDisplayed,
      notifications,
      addSnackbar,
      closeSnackbar,
      enqueueSnackbar: enqueueNotistack,
      closeSnackbar: closeNotistack,
    } = props;

    const CloseButton = key => (
      <Button onClick={() => closeNotistack(key)}>
        <Close style={{ color: 'white' }}/>
      </Button>
    );

    const i18n = useService('i18n');
    React.useEffect(() => {
      if (!notifications) return;

      notifications
        .filter(Boolean)
        // eslint-disable-next-line unicorn/no-array-for-each
        .forEach(({
          key,
          message,
          messageCode,
          remediationCode,
          variant,
          state,
          options: origOptions = {},
        }) => {
          const options = {
            ...origOptions,
            variant,
            action: CloseButton,
          };
          switch (state) {
          case 'visible':
            // skip visible alerts.
            break;

          case 'closed':
            closeNotistack(key);
            break;

          case 'queued':
            if (notifications && notifications.some(({
              message: aMessage,
              key: aKey,
            } = {}) => (aMessage === message && aKey !== key))) {
              // remove duplicates (name message, different key)
              removeSnackbar(key);
            } else {
              addDisplayed(key);
              const message_ = i18n.translate(messageCode || 'unknown', message);
              enqueueNotistack(message_, {
                key,
                ...options,
                onClose: (event, reason, myKey) => {
                  if (options.onClose) {
                    options.onClose(event, reason, myKey);
                  }
                },
                onExited: (event, myKey) => {
                  removeSnackbar(myKey);
                },
              });
            }
            break;

          case 'dismissed':
            closeSnackbar(key);
            break;

          default:
          }

        });
    }, [notifications]);

    return null;
    // return (
    //   <Button
    //     onClick={() => addSnackbar(
    //       `Test ${Random.id()}`,
    //       Random.choice(['success', 'info', 'warning', 'error']),
    //     )}
    //   >Add</Button>
    // );
  }),
);
