import { takeEvery } from 'redux-saga/effects'
import { declareAction, AsyncAction } from '@avicado/platform';

export default declareAction(
  'application:authentication',
  'Error',
  class Error extends AsyncAction {
    *doAction(action) {
      return yield action; //eslint-disable-line require-yield
    }
  },
  { effect: takeEvery }
);
