export const wholeCollectionIsReady = (collection) => Object.keys(collection)
  // eslint-disable-next-line unicorn/no-array-reduce
  .reduce((r, name) => {
    return r && collection[name] === 'ready';
  }, true);

export const systemIsReady = (state) => {
  const appsReady = wholeCollectionIsReady(state.applications);
  const svcsReady = wholeCollectionIsReady(state.services);
  return appsReady && svcsReady;
};
