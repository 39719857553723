import { createSelector } from 'reselect';
import { util } from '@avicado/platform';


const selectSystemApp = (state = {}) => state[util.applicationKey('system')];

const makeSelectSystemReady = () =>
  createSelector(
    selectSystemApp,
    ({ systemReady = false } = {}) => systemReady);

export default makeSelectSystemReady;
