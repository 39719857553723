export const sortMenuItems = (a, b) => {
  if (a.order === 'last' && b.order === 'last') return 0;
  if (a.order === 'last') return 1;
  if (b.order === 'last') return -1;
  return a.order - b.order;
};

export const onlyVisibleEntries = (menu) => {
  if (menu.visible !== null && menu.visible !== undefined) {
    if (typeof menu.visible === 'boolean') return menu.visible;
    return menu.visible(menu.client);
  }
  return true;
};

/**
 *
 * @param entries {[]}
 * @returns {[[][]]}
 */
export function splitEntries(entries) {
  if(!entries) return [[],[]];
  return entries
    .filter((element) => onlyVisibleEntries(element))
    .sort(sortMenuItems)
    // eslint-disable-next-line unicorn/no-array-reduce
    .reduce(
      ([before, after], entry) => {
        if (entry.order === 'last') {
          return [before, [...after, entry]];
        }
        return [[...before, entry], after];
      },
      [[], []],
    );
}
