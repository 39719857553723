import * as actions from '../actions';
import {
  defaultAddedSubscriptionReducer,
  defaultRemovedSubscriptionReducer,
  defaultChangedSubscriptionReducer,
} from '@avicado/react-platform-components';


export default {
  [actions.LoadProjects.type]: (action, state, draft) => {
    const { data: projects } = action;
    draft.projects = projects;
    // draft.currentProjectId = null;
    const { currentProjectId } = state;
    draft.currentProjectId = currentProjectId;
  },
  [actions.LoadProjects.types.changed]: defaultChangedSubscriptionReducer('projects', { actionProp: 'data' }),
  [actions.LoadProjects.types.added]: defaultAddedSubscriptionReducer('projects', { actionProp: 'data' }),
  [actions.LoadProjects.types.removed]: defaultRemovedSubscriptionReducer('projects', { actionProp: 'data' }),

  [actions.SelectProject.type]: (action, state, draft) => {
    const { id } = action;
    draft.currentProjectId = id === 'null' ? null : id;
  },
  [actions.LockProject.type]: (action, state, draft) => {
    draft.projectIsLocked = true;
  },
  [actions.UnlockProject.type]: (action, state, draft) => {
    draft.projectIsLocked = false;
  },
  [actions.UnSelectProject.type]: (action, state, draft) => {
    draft.currentProjectId = null;
  },
};
