import React from 'react';

const styles = {
  progressBar: {
    width: '100px',
    height: '14px',
    border: '1px solid',
    borderColor: '#aaaaaa',
    display: 'inline-block',
  },
  fill: {
    height: '100%',
    backgroundColor: '#eeeeee',
  }
}

export function ProgressBar(props) {
  const { value = 50, borderColor = styles.progressBar.borderColor, color = styles.fill.backgroundColor, height, width, ...others } = props;
  const fillProps = {
    width: `${value}%`,
    backgroundColor: color,
  }
  const borderProps = {
    borderColor,
    height: height || styles.progressBar.height,
    width: width || styles.progressBar.width,
  }
  return (
    <div className='progress-bar' style={{ ...styles.progressBar, ...borderProps }} {...others} >
      <div className='progress-bar-fill' style={{ ...styles.fill, ...fillProps }}></div>
    </div>
  );
}

export default ProgressBar;
