import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { ChangeLoginComponent as ChangeLoginComponentAction, Login as LoginAction } from '../actions';
import { LoginForm } from '../components';
import { defaultPath } from '../constants';
import makeSelectIsAuthenticated from '../selectors/isAuthenticated';
import makeSelectAuthLoginError from '../selectors/loginError';

const tabs = {
  DEFAULT: 1,
  LOGIN: 1,
  FORGOT_PASSWORD: 2,
};


function Login({ onSubmit, onForgotPassword, error, location, isAuthenticated, tabIndex } = {}) {
  const nextPath = (location && location.state && location.state.from && location.state.from.pathname) || defaultPath;

  if ( isAuthenticated ) {
    return <Redirect to={{ pathname: defaultPath, state: { from: location } }}/>
  }

  return (
    <Container maxWidth="md">
      <LoginForm
        onSubmit={onSubmit}
        onForgotPassword={onForgotPassword}
        errors={{ error }}
        nextPath={nextPath}
      />
    </Container>
  );
}

Login.propTypes = {
  onSubmit: PropTypes.func,
  onForgotPassword: PropTypes.func,
  error: PropTypes.string,
  location: PropTypes.object,
};

Login.defaultProps = {
  tabIndex: tabs.DEFAULT,
};

const mapStateToProps = createStructuredSelector({
  error: makeSelectAuthLoginError(),
  isAuthenticated: makeSelectIsAuthenticated(),
});


export function mapDispatchToProps(dispatch) {
  return {
    onSubmit: doc => dispatch(LoginAction.perform(doc)),
    onForgotPassword: evt => {
      if (evt !== undefined && evt.preventDefault) evt.preventDefault();

      dispatch(ChangeLoginComponentAction.perform({ index: tabs.FORGOT_PASSWORD }))
    },
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
)(withRouter(Login));
