import { Application, whenApplicationReady, whenServiceReady } from '@avicado/platform';

import { ExitToApp } from '@material-ui/icons';
import { produce } from 'immer';
import React from 'react';
import { Route } from 'react-router-dom';
import {
  ChangeLoginComponent,
  Login,
  Logout,
  Refresh,
  RefreshWatcher,
  RefreshWidget,
  Restore,
} from './actions';
import { loginPath, logoutPath } from './constants';
// import eventTypes from './actions/eventTypes';
import AuthenticationWidgetContainer from './containers/AuthenticationWidgetContainer';
import LoginContainer from './containers/Login';
import LogoutContainer from './containers/Logout';
import * as translations from './translations';

// import EVENT_TYPES from '@avicado/platform';

class AuthenticationApp extends Application {
  get name() {
    return 'Authentication';
  }

  get widgets() {
    return {
      authentication: { name: 'Authentication', width: 2, height: 3,  component: AuthenticationWidgetContainer },
    }
  }

  get routes() {
    return [
      <Route key="login" path={loginPath} component={LoginContainer} />,
      <Route key="logout" path={logoutPath} component={LogoutContainer} />,
    ];
  }

  get actions() {
    return [
      Login,
      Logout,
      Restore,
      Refresh,
      RefreshWidget,
      RefreshWatcher,
    ];
  }

  get menus() {
    return {
      main: {
        // logout: { title: 'Logout', route: 'logout', order: 'last', visible: a => a.authentication.isAuthenticated, icon: <ExitToApp/> },
        login: { title: 'Login', route: 'login', order: 1000, visible: a => !a.authentication.isAuthenticated },
      },
      account: {
        logout: { title: 'Logout', route: 'logout', order: 'last', visible: a => a.authentication.isAuthenticated, icon: <ExitToApp/> },
      },
    };
  }

  get translations() {
    return translations;
  }

  async ready() {
    super.ready();


    whenServiceReady('storage',  async (storage) => {
      let restore = async () => {
        // system is necessary b/c it has the load_applications actions which are fired on restore
        const state = await storage.getValue('auth', 'authentication');
        if (state) {
          this.client.events.dispatch(Restore.perform({ state }));
        }
      };

      whenApplicationReady('system', async () => {
        if (restore) {
          await restore();
          restore = null;
        }

      })
    });
  }

  reducer(state, action) {
    if (!state) return this.defaultState;

    const { type } = action;

    if (type === Logout.types.end) {
      return this.defaultState;
    }

    return produce(state, draft => {

      switch(type) {
      case ChangeLoginComponent.type:
        draft.tabIndex = action.index;
        break;
      case RefreshWidget.type:
        draft.time = action.time;
        break;
      default:
        break;
      }
    })
  }
}

export default AuthenticationApp;
