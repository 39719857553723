import SimpleSchema from 'simpl-schema';
import messages from './messages';

const schema = new SimpleSchema({
  email: {
    type: String,
    label: 'Email Address',
    uiType: 'username'
  },
  password: {
    type: String,
    optional: false,
    uiType: 'password',
    autoComplete: 'password',
    // min: 8,
    // custom: passwordValidator,
  },
  nextPath: {
    type: String,
    uiType: 'hidden',
  }
});

// custom error messages
schema.messageBox.messages(messages);

export default schema;
