import { createSelector } from 'reselect';
import { selectSystemApp } from './select-system-app';


export const selectSnackbarNotifications = createSelector(
  selectSystemApp,
  ({ snackbarNotifications } = {}) => {
    return snackbarNotifications || [];
  },
)
