import { put, takeEvery } from 'redux-saga/effects';
import { AsyncObserver, declareObserver, Platform } from '@avicado/platform';
import { ApplicationPreferences } from './application-preferences';
import { LoadApplication } from './load-application';

// eslint-disable-next-line unicorn/prevent-abbreviations
export const LoadApplicationWatcher = declareObserver(
  'application:system',
  'LoadAppWatcher',
  class LoadAppWatcher extends AsyncObserver {
    *invoke() {
      const { name, ...others } = this.action;
      yield put(ApplicationPreferences.perform({ key: name }));
      yield put(ApplicationPreferences.subscribe({ key: name }));

      const events = Platform.getInstance().events;

      return { name, ...others };
    }
  },
  { effect: takeEvery, pattern: LoadApplication.type }
);

export default LoadApplicationWatcher;
