import { isNullish } from '@avicado/util';
import { createSelector } from 'reselect';
import { selectSystemApp } from './select-system-app';


export const selectCurrentProject = createSelector(
  selectSystemApp,
  ({ currentProjectId, projects } = {}) =>
    isNullish(projects) ? null : projects.find(({ id }) => id === currentProjectId),
)

