import { EVENT_TYPES } from '@avicado/platform';
import * as actions from '../actions';
import { wholeCollectionIsReady } from '../utils';


export default {
  [EVENT_TYPES.APPLICATION_REGISTER]: (action, state, draft) => {
    draft.applications[action.slug] = 'register';
    draft.applicationsReady = false;
    draft.systemReady = false;
  },
  [EVENT_TYPES.APPLICATION_READY]: (action, state, draft) => {
    draft.applications[action.slug] = 'ready';
  },
  [actions.ApplicationStartupComplete.type]: (action, state, draft) => {
    draft.applicationsReady = wholeCollectionIsReady(state.applications);
  },
  [EVENT_TYPES.APPLICATION_INIT]: (action, state, draft) => {
    draft.applications[action.slug] = 'initialize';
    draft.applicationsReady = false;
    draft.systemReady = false;
  },
  [EVENT_TYPES.APPLICATION_SHUTDOWN]: (action, state, draft) => {
    draft.applications[action.slug] = 'shutdown';
  },

};
