/* eslint-disable unicorn/prefer-module, import/no-unassigned-import */
require('./apm');

import { Log, initialize, ConsoleLogWriter } from '@avicado/log';
const minLevel = process.env.REACT_APP_LOG_LEVEL || 'EVERYTHING';

if(process.env.NODE_ENV !== 'production') {
  console.debug(`Initializing log to show messages at least as serious as ${minLevel}`);
}

initialize({ minLevel });

const log = Log.create('startup');

log.debug('Begin startup sequence');

log.debug('Load schema extensions');
require('./simple-schema');

log.debug('Start i18n');
require('./intl');

log.debug('Initialize platform');
require('./platform'); // Probably want this last, or close to it.  Starts the app.

log.debug('Setup cookie handling');
require('./cookie-storage-classes');

log.debug('Populate the application registry');
require('./dynamic-applications');
/* eslint-enable unicorn/prefer-module, import/no-unassigned-import */
