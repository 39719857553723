import { gql } from '@apollo/client';
import { declareAction , GraphQLQueryAction } from '@avicado/platform';
import { takeEvery } from 'redux-saga/effects';


const QUERY = gql`
  query application($key: String) {
    application(key: $key) {
      key
      displayName
      data
    }
  }
`;

const SUBCRIPTION_QUERY = gql`
  subscription application($key: String) {
    applicationData(key: $key) {
      key
      displayName
      data
    }
  }
`;

// eslint-disable-next-line unicorn/prevent-abbreviations
export const ApplicationPreferences = declareAction(
  'application:system',
  'AppPreferences',
  class AppPreferences extends GraphQLQueryAction {
    get options() {
      return {};
    }

    get query() {
      return QUERY;
    }

    static get subscriptionQuery() {
      return SUBCRIPTION_QUERY;
    }

    * doAction(variables = {}) {
      const authSvc = this.client.authentication;
      if (authSvc.isAuthenticated) {
        return yield* super.doAction(variables);
      }
      throw new Error('Cannot query when unauthenticated');
    }
  },
  { effect: takeEvery }
)
export default ApplicationPreferences;
