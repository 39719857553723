import { actions } from '@avicado/authentication-app';
import { AsyncObserver, declareObserver, Platform } from '@avicado/platform';
import { call, put, takeLatest } from 'redux-saga/effects';
import { CloseSnackbar } from './close-snackbar';
import { UnloadApplications } from './unload-applications';

export const LogoutWatcher = declareObserver(
  'application:system',
  'LogoutWatcher',
  class LogoutWatcher extends AsyncObserver {
    *invoke() {
      yield put(UnloadApplications.perform());
      yield put(CloseSnackbar.perform({ dismissAll: true }));
      const graphql = Platform.serviceFor('graphql');
      try {
        yield call(graphql.reset)
      } catch {
        // ignore errors
      }
    }
  },
  { effect: takeLatest, pattern:  actions.Logout.type }// 'Application/authentication/Logout' }
);


