import { AsyncObserver, declareObserver } from '@avicado/platform';
import { put, takeLatest } from 'redux-saga/effects';
import { LoadApplications } from './load-applications';
import { actions } from '@avicado/authentication-app'
import { LoadOrganizations } from './load-organizations';

export const RestoreWatcher = declareObserver(
  'application:system',
  'RestoreWatcher',
  class RestoreWatcher extends AsyncObserver {
    *invoke() {
      yield put(LoadOrganizations.perform());
      yield put(LoadApplications.perform());
    }
  },
  { effect: takeLatest, pattern:  actions.Restore.types.end } // 'Application/authentication/Restore/End' }
);


