import { AsyncObserver, declareObserver, EVENT_TYPES } from '@avicado/platform';
import { put, takeLatest } from 'redux-saga/effects';
import { ServiceStartupComplete } from './service-startup-complete';


export const ServiceReadyWatcher = declareObserver(
  'application:system',
  'ServiceReadyWatcher',
  class ServiceReadyWatcher extends AsyncObserver {
    *invoke() {
      yield put(ServiceStartupComplete.perform(this.action))
    }
  },
  { effect: takeLatest, pattern: EVENT_TYPES.APPLICATION_READY }
);


