import { Platform , loadApplication } from '@avicado/platform';

import ReactRenderer from '../react-renderer';

async function initialApps() {
  return [
    { name: 'authentication', source: import('@avicado/authentication-app') },
    { name: 'system', source: import('@avicado/system-app') },
    { name: 'dashboard', source: import('@avicado/dashboard-app') },
  ];
}

async function start() {
  window.platform = await Platform.startAsync();
  const apps = await initialApps();
  Promise.all(
    apps.map(
      ({ name, source }) => loadApplication(name, () => source)
    )
    // eslint-disable-next-line promise/prefer-await-to-then
  ).catch(error => console.error('Loading application failed \n' + error.stack));
  ReactRenderer.getInstance().renderInto('root');
}

start();
