// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2000,
    color: '#fff',
  },
  progress: {
    color: '#fff',
  }
}));

export function LoginWaiting(props) {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress className={classes.progress}/>
    </Backdrop>
  );
}

// <Typography><Translate>loginWaiting</Translate></Typography>
