import { gql } from '@apollo/client';
import { applicationKey, declareAction, makeQueryAction } from '@avicado/platform';
import { takeLatest } from 'redux-saga/effects';

const QUERY = gql`
    {
        projects {
            id
            name
            organization {
                id
            }
            users {
                id
            }
            abilities
        }
    }
`;

const addedSubscriptionQuery = gql`
    subscription {
        projectAdded {
            id
            name
            organization {
                id
            }
            abilities
            users {
                id
            }
        }
    }
`;

const changedSubscriptionQuery = gql`
    subscription {
        projectChanged {
            id
            name
            organization {
                id
            }
            abilities
            users {
                id
            }
        }
    }
`;

const removedSubscriptionQuery = gql`
    subscription {
        projectRemoved {
            id
        }
    }
`;

export const LoadProjects = declareAction(
  applicationKey('system'),
  'LoadProjects',
  makeQueryAction('LoadProjects', QUERY, {
    changedSubscriptionQuery,
    addedSubscriptionQuery,
    removedSubscriptionQuery,
    actionProp: 'data',
    parseTypes: true,
  }),
  { effect: takeLatest }
);
