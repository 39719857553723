import { AsyncAction, declareAction } from '@avicado/platform';
import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import { getIntendedPath } from './util';


function restore(authentication, state) {
  try {
    return authentication.restore(state);
  } catch (error) {
    this.log.error(error);
  }
}

export default declareAction(
  'application:authentication',
  'Restore',
  class Restore extends AsyncAction {
    *doAction({ type, state }) {
      const authentication = this.client.authentication;
      const storage = this.client.serviceFor('storage');

      // restore
      const { token: { accessToken, refreshToken, expires } = {}, username } = JSON.parse(state);
      if (!accessToken || !refreshToken || !expires) throw new Error('No state to restore');

      const { accessToken: token, resourceToken } = yield call(restore, authentication, state) || {};

      // save the restored & refreshed value
      yield call(storage.setValue, 'auth', authentication.serializableState, 'authentication', { secure: true });
      return { type, state, token, email: username, resourceToken };
    }

    *doEndAction(parameters) {
      // restore intended path
      const router = this.client.serviceFor('router');
      const intendedPath = getIntendedPath(router.history.location);
      if (intendedPath) yield put(push(intendedPath));
      const notificationMessage = {
        message: 'Session Restored',
        variant: 'success',
      };
      return { notificationMessage, ...parameters };
    }

    *doHandleError(error, action) {  // eslint-disable-line require-yield
      const { message, code } = error;
      const { password, ...others } = action;
      return { ...others, message, code, action: Error.type, hideError: true };
    }
  }
);


