import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import makeConnectedContainer from '@avicado/react-connected-container';
import { LoginWaiting } from './components';
import { loginPath } from './constants';
import makeSelectIsAuthenticated from './selectors/isAuthenticated';
import makeSelectSystemReady from './selectors/make-select-system-ready';

export const ProtectedRoute = makeConnectedContainer(
  'ProtectedRoute',
  {
    state: {
      isAuthenticated: makeSelectIsAuthenticated(),
      systemReady: makeSelectSystemReady(),
    }
  },
  ({ component: Component, location, render: originalRender, isAuthenticated,  systemReady, ...rest }) => {
    const waitingForReady = isAuthenticated && !systemReady;
    const ready = isAuthenticated && systemReady;

    return <Route {...rest} render={(props) => {
      if (ready) {
        return originalRender()
      }

      // logged in but the system isn't ready to go
      if (waitingForReady) {
        return <LoginWaiting />
      }

      // needs to login
      return <Redirect to={{ pathname: loginPath, state: { from: props.location } }}/>;
    }}/>
  }
)
export default ProtectedRoute;
