import React from 'react';

function SysInfoWidget({ client }) {
  const allWidgets = client.platform.allWidgets;
  return (
    <div style={{ margin: '10px' }}>
      <div>
        <h3>Applications</h3>
        <ul>
          {client.platform.applications.values.map(app => <li key={app.key}>{app.name} ({app.key})</li>)}
        </ul>
      </div>
      <div>
        <h3>Widgets</h3>
        <ul>
          {Object.keys(allWidgets).map(appName => {
            const clientsWidgets = allWidgets[appName];
            return Object.keys(clientsWidgets).map( widgetSlug => {
              const widget = clientsWidgets[widgetSlug];
              return (<li key={widget.slug}>{client.name} - {widget.name} ({widget.slug})</li>)
            })
          })}
        </ul>
      </div>
      <div>
        <h3>Services</h3>
        <ul>
          {
            client.platform.services.values.map(service => <li key={service.key}>{service.name} ({service.key})</li>)
          }
        </ul>
      </div>
    </div>

  )
}

export default SysInfoWidget;
