import { applicationKey, AsyncAction, declareAction } from '@avicado/platform';
import { isRequired } from '@avicado/util';
import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import { codeToMessage } from '../util';
import Error from './Error';
import { getIntendedPath } from './util';


export default declareAction(
  applicationKey('authentication'),
  'Login',
  class Login extends AsyncAction {
    * doValidate({
      email,
      username,
      password = isRequired('password'), // eslint-disable-line no-unused-vars
    }) {
      if (!email && !username) yield isRequired('email or username');
    }

    * doAction({ email, username, password, type }) {
      const authentication = this.client.authentication;

      const result = yield call(
        authentication.authenticate,
        username || email,
        password,
      );

      const { accessToken } = result;
      return { type, email: username || email, accessToken, token: accessToken };
    }

    * doEndAction({ type, username, email, token, nextPath }) {
      const authentication = this.client.authentication;

      const storage = this.client.serviceFor('storage');
      yield call(storage.setValue, 'user', username || email, 'authentication');
      yield call(storage.setValue, 'auth', authentication.serializableState, 'authentication');

      // restore intended path
      const router = this.client.serviceFor('router');
      const intendedPath = getIntendedPath(router.history.location);
      if (intendedPath) yield put(push(intendedPath));
      const notificationMessage = {
        message: 'Login Successful',
        variant: 'success',
      };
      return { type, username, email, notificationMessage, accessToken: token, token };
    }

    * doHandleError(error, action) {  // eslint-disable-line require-yield
      const { message, code } = error;
      const { password, ...others } = action;
      const notificationMessage = {
        message: codeToMessage(code),
        variant: 'error',
      };
      return { ...others, message, code, notificationMessage, action: Error.type };
    }
  }
)
