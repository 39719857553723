import { EVENT_TYPES } from '@avicado/platform';


export default {
  [EVENT_TYPES.PLATFORM_INIT]: (action, state, draft) => {
    draft.platform = 'init';
    draft.systemReady = false;
    draft.servicesReady = false;
    draft.applicationsReady = false;
  },
  [EVENT_TYPES.PLATFORM_READY]: (action, state, draft) => {
    draft.platform = 'ready';
  },
};
