import { Platform } from '@avicado/platform';


export const codeToMessage = code => {
  const i18n = Platform.serviceFor('i18n');
  switch(code) { // TODO: i18n
  case 'EAUTH':
  case 'ESTATUS':
    return i18n.translate('application:authentication::invalidUserOrPass');
    // return 'Invalid username or password';
  case 'UNKNOWN':
  default:
    return i18n.translate('application:authentication::unknownError');
    // return "An unknown error occurred";
  }
};
