import StaticTextWidget from '../components/static-text-widget';
import SimpleSchema from 'simpl-schema';

const configSchema = new SimpleSchema({
  text: { type: String, optional: true },
});

export const staticText = {
  name: 'Static Text',
  width: 1,
  height: 1,
  component: StaticTextWidget,
  description: 'Write Some text, see it.',
  schemaProps: { configSchema }
}
