import { Translate } from '@avicado/react-platform-components';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';


const useStyles = makeStyles((theme) => ({
  footer: {
    color: '#cccccc',
    fontStyle: 'italic',
    fontSize: '8pt',
    padding: '20px',
    height: '100%',
    textAlign: 'center'
  },
  links: {
    margin: 0,
    '& a': {
      textDecoration: 'none',
      color: '#cccccc',
      '&:hover': {
        color: theme.palette.primary.main
      }
    },
  },
  span: {
    padding: '4px',
    fontWeight: 'bolder'
  }
}));

export const PlatformFooter = props => {
  const classes = useStyles(props);
  return (
    <Typography component="div" className={classes.footer}>
      <div>
        <Translate value="footer"/>
        <p className={classes.links}>
          <a href={'https://www.astrobuild.com/terms-of-service'} target={'_blank'} rel={'noreferrer'}><Translate value="termsOfService"/></a>
          <span className={classes.span}>|</span>
          <a href={'https://www.astrobuild.com/privacy-policy'} target={'_blank'} rel={'noreferrer'}><Translate value="privacyPolicy"/></a>
          <span className={classes.span}>|</span>
          <a href={'https://www.astrobuild.com/acceptable-use-policy'} target={'_blank'} rel={'noreferrer'}><Translate value="acceptableUsePolicy"/></a>
          <span className={classes.span}>|</span>
          <a href={'https://www.astrobuild.com/sla'} target={'_blank'} rel={'noreferrer'}><Translate value="sla"/></a>
        </p>
      </div>
    </Typography>
  );
};

export default PlatformFooter;

