import { gql } from '@apollo/client';
import { AsyncAction, declareAction } from '@avicado/platform';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { LoadApplication } from './load-application';


const appsQuery = gql`
  query {
    me {
      id
      applications {
        id
        key
        displayName
        data
      }
    }
  }
`;

// eslint-disable-next-line unicorn/prevent-abbreviations
export const LoadApplications = declareAction(
  'application:system',
  'LoadApps',
  class LoadApps extends AsyncAction {
    *doStartAction(parameters) {
      const { graphql } = this.client;
      const { data } = yield call(graphql.query, appsQuery);

      this.applications = data.me.applications;
      const apps = this.applications.map(({ key }) => key);
      return yield { ...parameters, applications: apps };
    }

    *doAction({ applications: apps = [], ...others }) {
      yield all(apps.map(name => put(LoadApplication.perform( { name, key: name }))))
      return { applications: apps, ...others };
    }
  },
  { effect: takeEvery }
);
export default LoadApplications;
