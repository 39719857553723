import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

const colors = {
  orange: '#F7560F',
  blue: '#064887',
  raspberry: '#BC204F',
  purple: '#31225D',
  lightBlue: '#7AA3CD',
  green: '#4AB873',
  yellow: '#E6C228',
  yellowgreen: '#9acd32',
  offWhite: '#F5F5F5',
  white: '#FFFFFF',
  grayBlue: '#e6ecf3'
};

const theme = createMuiTheme({
  spacing: 8,
  palette: {
    default: {
      main: colors.blue,
    },
    primary: {
      main: colors.blue,
      light: colors.lightBlue,
    },
    secondary: {
      main: colors.orange,
    },
    tertiary: {
      main: colors.offWhite,
      light: colors.grayBlue,
    },
    plain: {
      main: colors.white,
    },
    error: {
      main: red[500],
    },
    success: {
      main: colors.green
    },
    warn: {
      main: colors.yellow,
    }
  },
  typography: {
    useNextVariants: true,
    h1: {
      fontFamily: ['museo-slab', 'roboto slab thin', 'helvetica', 'arial', 'sans-serif'].join(','),
      fontWeight: 100,
    },
    h2: {
      fontFamily: ['museo-slab', 'roboto slab thin', 'helvetica', 'arial', 'sans-serif'].join(','),
      fontWeight: 100,
    },
    h3: {
      fontFamily: ['museo-slab', 'roboto slab regular', 'helvetica', 'arial', 'sans-serif'].join(','),
      fontWeight: 700,
    },
    h4: {
      fontFamily: ['museo-slab', 'roboto slab regular', 'helvetica', 'arial', 'sans-serif'].join(','),
      fontWeight: 700,
    },
    h5: {
      fontFamily: ['museo-sans-rounded', 'roboto bold', 'helvetica', 'arial', 'sans-serif'].join(','),
      fontWeight: 700,
    },
    h6: {
      fontFamily: ['museo-sans-rounded', 'roboto bold', 'helvetica', 'arial', 'sans-serif'].join(','),
      fontWeight: 700,
      fontSize: '12pt',
    },
    subtitle1: {
      fontFamily: ['museo-sans-rounded', 'roboto black', 'helvetica', 'arial', 'sans-serif'].join(','),
      fontWeight: 900,
    },
    body1: {
      fontFamily: ['museo-sans-rounded', 'roboto regular', 'helvetica', 'arial', 'sans-serif'].join(','),
      fontWeight: 500,
      // color: 'rgb(49, 34, 93)',
    },
    body2: {
      fontFamily: ['museo-sans-rounded', 'roboto regular', 'helvetica', 'arial', 'sans-serif'].join(','),
      fontWeight: 500,
      color: 'rgb(49, 34, 93)',
    },
  },
});


export default theme;
