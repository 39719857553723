import { AsyncObserver, declareObserver } from '@avicado/platform';
import { actions } from '@avicado/authentication-app'
import { put, takeLatest } from 'redux-saga/effects';
import { LoadApplications } from './load-applications';
import { LoadOrganizations } from './load-organizations';


export const LoginWatcher = declareObserver(
  'application:system',
  'LoginWatcher',
  class LoginWatcher extends AsyncObserver {
    *invoke() {
      yield put(LoadOrganizations.perform());
      yield put(LoadApplications.perform());
    }
  },
  { effect: takeLatest, pattern: actions.Login.types.end } //'Application/authentication/Login/End' }
);


