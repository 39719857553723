import { Platform } from '@avicado/platform';
import { classSet } from '@avicado/util';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight, FiberManualRecord } from '@material-ui/icons';
import React from 'react';
import { matchPath } from 'react-router-dom';
import theme from '../theme';
import { NavLinkRef } from './nav-link-ref';
import { splitEntries } from './util/menu';
import PropTypes from 'prop-types';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  // these style defs fixes SD-95
  listItem: {
    paddingLeft: 16,
    paddingRight: 16,
    display: 'flex',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8,
    justifyContent: 'flex-start',
  },
}));

const longestToShortest = ({ props: propsA }, { props: propsB }) => {
  const { path: pathA = '' } = propsA;
  const { path: pathB = '' } = propsB;
  return pathB.length - pathA.length;
};

const useCurrentRoute = (platform = Platform.getInstance()) => {
  // eslint-disable-next-line no-restricted-globals
  const currentPath = location.pathname;
  const routes = platform.routes;

  return routes
    .sort(longestToShortest) // longest path to shortest path
    // eslint-disable-next-line unicorn/no-array-reduce
    .reduce((matchedRoute, route) => {
      if (matchedRoute) {
        return matchedRoute;
      }
      const { key, props } = route;
      const { path } = props;
      const result = matchPath(currentPath, { path });
      if (result) {
        return key;
      }
      return null;
    }, null);
};

export function MainMenu(props) {
  const { platform, menu, open, onToggleMenu, isAuthenticated } = props;
  const entries = platform.menus.valueFor(menu);
  // const ctx = {};
  // const entries = platform.menus.getMenu(menu, ctx);
  const classes = useStyles();
  const currentRoute = useCurrentRoute(platform);

  if (!entries) {
    return null;
  }

  const menuFor = ({ title, path }) => {
    return <ListItem
      button
      className={classes.listItem}
      key={title.toLowerCase()}
      component={NavLinkRef}
      to={path}
    >
      <ListItemText primary={title} />
    </ListItem>
  }

  const listItemFor = ({ title: name, path, icon, route, subMenus = [] }) => {
    const navName = name.includes(' ') ? name.split(' ').join('-') : name;
    const subItemFrags = Array.isArray(subMenus) ? subMenus.map(subItem => menuFor(subItem)) : null;
    const iconComponent = icon || <FiberManualRecord/>;

    // for /*button*/, please see
    // https://avicado.atlassian.net/browse/SD-95?atlOrigin=eyJpIjoiODA2ZjdiMmIwYTNhNDZiOGI1ODA0YzdjOGU5YWJhYzQiLCJwIjoiaiJ9
    return (
      <React.Fragment>
        <ListItem
          button
          className={classes.listItem}
          key={name.toLowerCase()}
          component={NavLinkRef}
          to={path}
          selected={route === currentRoute}
          data-test={`${navName.toLowerCase()}-menu-item`}
        >
          <ListItemIcon>{iconComponent}</ListItemIcon>
          <ListItemText primary={name}/>
        </ListItem>
        {subItemFrags}
      </React.Fragment>
    );
  };

  const [entriesBeforeBreak, entriesAfterBreak] = splitEntries(Object.values(entries));
  const listItems = [
    ...entriesBeforeBreak.map((element) => listItemFor(element)),
    entriesAfterBreak.length > 0 ? <Divider/> : undefined,
    ...entriesAfterBreak.map((element) => listItemFor(element)),
  ].filter(Boolean);

  let chevron;
  if (theme.direction === 'ltr') {
    chevron = open ? <ChevronLeft/> : <ChevronRight/>;
  } else {
    chevron = open ? <ChevronRight/> : <ChevronLeft/>;
  }
  if (!isAuthenticated) {
    return null;
  }
  return (
    <nav>
      <Drawer
        variant="persistent"
        anchor="left"
        elevation={0}
        open={true}
        className={classSet({
          [classes.drawer]: true,
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: classSet({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={onToggleMenu}>{chevron}</IconButton>
        </div>
        <Divider/>
        <List data-test="main-menu">{listItems}</List>
      </Drawer>
    </nav>
  );
}

MainMenu.propTypes = {
  platform: PropTypes.object,
  menu: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onToggleMenu: PropTypes.func,
  isAuthenticated: PropTypes.bool,
}

export default MainMenu;
