import { gql } from '@apollo/client';
import { applicationKey, declareAction, makeQueryAction } from '@avicado/platform';


const QUERY = gql`
  {
    organizations {
      id
      name
      projects {
        id
        name
      }
      adminGroup {
            id
            name
        }
      standardGroup {
            id
            name
        }
      readOnlyGroup {
            id
            name
        }
    }
  }
`;

export const LoadOrganizations = declareAction(
  applicationKey('system'),
  'LoadOrganizations',
  makeQueryAction('LoadOrganizations', QUERY),
);
