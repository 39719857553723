import { AsyncAction, declareAction } from '@avicado/platform';
import { call, put } from 'redux-saga/effects';
import Error from './Error';


export default declareAction(
  'application:authentication',
  'Logout',
  class Logout extends AsyncAction {
    *doAction(action) {
      const authentication = this.client.authentication;
      yield call(authentication.deauthenticate);

      return action;
    }

    *doEndAction({ type }) {
      const storage = this.client.serviceFor('storage');
      storage.setValue('user', '', 'authentication');
      storage.setValue('auth', '', 'authentication');

      const systemApp = this.client.platform.applicationFor('system');
      if (!systemApp) throw new Error('Cannot lookup application: system');

      const UnloadApps = systemApp.actions.find(a => a.name === 'UnloadApps');
      if (!UnloadApps) throw new Error('Missing action: system.UnloadApps');

      yield put(UnloadApps.perform());

      // WARNING: This forces a reload of everything because state seems to get a little out of
      //   whack/after a login -> logout -> login scenario
      // window.location.reload();

      const notificationMessage = {
        message: 'Logout Successful',
        variant: 'success',
      };

      return { type, notificationMessage };
    }
  }
);
