import { isNullish } from '@avicado/util';
import { createSelector } from 'reselect';
import { selectSystemApp } from './select-system-app';


export const selectCurrentOrganization = createSelector(
  selectSystemApp,
  ({ currentOrganizationId, organizations } = {}) =>
    isNullish(organizations) ? null : organizations.find(({ id }) => currentOrganizationId === id ),
);
