import React from 'react';
import PropTypes from 'prop-types'
import { EVENT_TYPES } from '@avicado/platform';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { ConnectedRouter as Router } from 'connected-react-router';
import { Provider, ReactReduxContext } from 'react-redux';
import { Layout } from './components';
import ApplicationStateContainer from './containers/application-state-container';
import theme from './theme';


const App = ({ platform }) => {
  const { history } = platform.serviceFor('router');
  return <>
    {
      <Provider store={platform.events.store} context={ReactReduxContext}>
        <CssBaseline/>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <Layout platform={platform}/>
          </Router>
        </ThemeProvider>
      </Provider>
    }
  </>;
};

App.propTypes = {
  platform: PropTypes.shape({
    events: PropTypes.shape( {
      store: PropTypes.object.isRequired
    }).isRequired,
    serviceFor: PropTypes.func.isRequired,
  }),
}

const AppContainer = () => {
  return <>
    <ApplicationStateContainer events={[
      EVENT_TYPES.SERVICE_READY,
      EVENT_TYPES.APPLICATION_READY,
      EVENT_TYPES.PLATFORM_READY,
    ]}>
      { /* platform is injected automatically */}
      <App/>
    </ApplicationStateContainer>
  </>;
};

export default AppContainer;

