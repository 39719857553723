import { memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { actionDispatch } from './action-dispatch';
import { withInitializer } from './with-initializer';


function makeConnectedContainerInt(
  name,
  { state: stateProps = {}, dispatch: dispatchProps = {}, initialize = () => {} } = {},
  renderFn = () => {},
) {
  Object.defineProperty(renderFn, 'name', { value: name });

  const dispatchFuncs = dispatch => {
    // eslint-disable-next-line unicorn/no-array-reduce
    return Object.keys(dispatchProps).reduce((object, key) => {
      const target = dispatchProps[key];
      const func = actionDispatch(target);
      // console.warn('FUNC:', func);
      object[key] = func(dispatch);
      return object;
    }, {});
  };


  const withConnect = connect(
    createStructuredSelector(stateProps),
    dispatchFuncs,
  );
  return compose(withConnect, memo)(renderFn);
}


/**
 * Shorthand for making a container component with mapDispatchToProps and
 * createStructuredSelector.
 *
 * This:
 * ```
 *  export default makeConnectedContainer(
 *  'UserProfileContainer',
 *  {
 *     state: {
 *       doc: makeSelectUserProfileDoc(),
 *     },
 *     dispatch: {
 *       LoadUserProfile,
 *     }
 *   },
 *  function(props) {
 *     const { LoadUserProfile } = props;
 *     useQueryAction(LoadUserProfile);
 *
 *     const { doc, ...others} = props;
 *     return  <UserProfile {...doc} {...others} />;
 *   }
 *  )
 * ```
 * Is equivalent to:
 * ```
 *  function UserProfileContainer(props) {
 *   const { loadUserProfile } = props;
 *   useQueryAction(loadUserProfile);
 *
 *   const { doc, ...others} = props;
 *   return  <UserProfile {...doc} {...others} />;
 * }
 *
 *  const mapStateToProps = createStructuredSelector({
 *   doc: makeSelectUserProfileDoc(),
 * });
 *
 *
 *  export function mapDispatchToProps(dispatch) {
 *   return {
 *     loadUserProfile: LoadUserProfile.dispatchWith(dispatch),
 *   };
 * }
 *
 *  const withConnect = connect(
 *  mapStateToProps,
 *  mapDispatchToProps,
 *  );
 *
 *  export default compose(
 *  withConnect,
 *  memo,
 *  )(UserProfileContainer);
 *  ```
 * @param name {string} - the name of the resulting react component
 * @param opts {{}}
 * @param opts.state {object}- props that come from state e.g. selectors
 * @param opts.dispatch {object}- actions or functions for dispatch.  Actions that need
 * params can be invoked by using `dispatch => ActionName.dispatchWith(dispatch, 'index')`.  In
 * this case, 'index' is the action property that the first argument to the dispatch would be
 * assigned.
 * @param opts.initialize {function|object} a dispatch function or Action to run at first
 * initialization
 * @param renderFn {function} - A standard react component function
 * @returns {function} - a react component
 */
export function makeConnectedContainer(
  name,
  options = {},
  renderFn = () => {},
) {
  const { initialize, deinitialize, ...otherOptions } = options;
  const primaryContainer = makeConnectedContainerInt(name, otherOptions, renderFn);

  return initialize ? withInitializer(initialize, deinitialize, primaryContainer): primaryContainer;
}

