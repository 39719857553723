import * as actions from '../actions';


export default {
  [actions.LoadApplications.type]: (action, state, draft) => {
    draft.dynamicApplications = [
      ...(state.dynamicApplications || []),
      ...action.applications,
    ];
    draft.applications = {
      ...state.applications,
      // eslint-disable-next-line unicorn/no-array-reduce
      ...(action.applications.filter(Boolean).reduce((all, app) => ({ ...all, [app]: 'requested' }), {})),
    };
  },
  [actions.LoadApplications.types.error]: (action, state, draft) => {
    draft.applications[action.slug] = { status: 'error', message: action.message };
  },
};
