export const defaultState = {
  // are all services and applications ready
  systemReady: false,

  // are all applications ready
  applicationsReady: false,

  // are all services ready
  servicesReady: false,

  services: {},
  applications: {},
  dynamicApplications: [],
  preferences: {},

  // the selected organizationId
  currentOrganizationId: null,

  // can the current Organization Id be changed?
  organizationIsLocked: false,

  // the selected project id or null for all
  currentProjectId: null,

  // can the current Project Id be changed?
  projectIsLocked: false,

  // the list of available organization objects
  organizations: [],

  // the list of available project objects for this organization
  projects: [],

  // the running list of the snackbar notifications, regardless of state
  snackbarNotifications: [],
};
