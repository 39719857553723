import Application from './application';
import ApplicationPreferences from './application-preferences';
import LoadApplications from './load-applications';
import Organizations from './organizations';
import Platform from './platform';
import Project from './project';
import Service from './service';
import SetUserApplicationPreferences from './set-user-application-preferences';
import Snackbars from './snackbars';
import SystemReady from './system-ready';
import UnloadApplication from './unload-application';


export const reducers = Object.assign(
  {},
  Application,
  ApplicationPreferences,
  LoadApplications,
  Organizations,
  Platform,
  Project,
  Service,
  SetUserApplicationPreferences,
  Snackbars,
  SystemReady,
  UnloadApplication,
);
