import { whenServiceReady } from '@avicado/platform';

const TWO_HOURS = 60 * 60 * 2;
const ONE_YEAR = 365 * 24 * 60 * 60;

const allowCookies = true;

whenServiceReady('storage', async(storage) => {
  await storage.setStorageClassPermission('authentication', allowCookies, { secure: true, 'max-age': TWO_HOURS });
  await storage.setStorageClassPermission('performance', allowCookies, { secure: true });
  await storage.setStorageClassPermission('tracking', allowCookies, { secure: true, 'max-age': ONE_YEAR });
  await storage.setStorageClassPermission('preferences', allowCookies, { 'max-age': ONE_YEAR });
})
