import React from 'react';

function StaticTextWidget(props) {
  const { text } = props;
  return <div style={{ height: '100%', margin: '5px' }}>{text}</div>;
}

export default StaticTextWidget;


StaticTextWidget.defaultProps = {
  text: ''
}
