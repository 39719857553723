import { makeStyles } from '@material-ui/core';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectIsAuthenticated } from '@avicado/authentication-app';

const useStyles = makeStyles(theme => ({
  content: {
    padding: '20px',
  },
}));

const reallyLong = '-'.repeat(999);

const orderRoutes = (a,b) => {
  const { props: { path: aPath = reallyLong, last: aLast = false } = {} } = a;
  const { props: { path: bPath = reallyLong, last: bLast = false } = {} } = b;

  if (!!(aLast ^ bLast)) {
    return aLast ? 1 : -1;
  }
  return aPath.length - bPath.length;
};


//eslint-disable-next-line unicorn/prevent-abbreviations
function ApplicationContent(props) {
  const { clients, applications, isAuthenticated, ...componentProps } = props;
  const classes = useStyles(props);

  const routes = applications && [
    // eslint-disable-next-line unicorn/no-array-reduce
    ...applications.reduce((p, client) => {
      const clientRoutes = client.routes.map((r) => {
        const { component: Component } = r.props;
        const render = () => (<Component { ...componentProps} client={client} />);
        return React.cloneElement(r, { render, component: undefined, isAuthenticated });
      }) || [];
      return [...p, ...clientRoutes];
    }, [])
  ].sort(orderRoutes);

  return (
    <div className={`application-content ${classes.content}`} >
      <Switch>{routes}</Switch>
    </div>
  )
}
ApplicationContent.propTypes = {
  clients: PropTypes.array,
  applications: PropTypes.array,
  isAuthenticated: PropTypes.bool,
};

ApplicationContent.defaultProps = {
  isAuthenticated: false,
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: makeSelectIsAuthenticated(),
});

export function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
)(withRouter(ApplicationContent));


// export default withConnect(ApplicationContent)  ;
// export default ApplicationContent;
